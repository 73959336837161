/* common css starts here */

/* Common colours */
.text-color {
  color: #073763;
}

#loading-icon {
  position: absolute;
  float: left;
  top:45%;
  left:0;
  right: 0;
  text-align: center;
  margin: auto;
  z-index: 999;
}

#monthly-overview-loading-icon {
  position: fixed;
  float: left;
  top:45%;
  left:0;
  right: 0;
  text-align: center;
  margin: auto;
  z-index: 999;
}

.custom-loading-icon {
  color: #61bfb5 !important;
  width: 5rem !important;
  height: 5rem !important;
}

.border-blue {
  /* border: 1px solid #61bfb5 !important; */
  /* border: none; */
  /* border-bottom: 1px solid #61bfb5 !important;  */
}

.dark-color {
  color: #292929;
}

#text-indii-blue {
  color: #61bfb5;
  font-weight: bolder !important;
}

#text-indii-dark-blue {
  color: #073763 !important;
  font-weight: bolder !important;
}

.bg-right-container {
  background-color: #f3f3f3;
}

.background-indii-blue {
  background-color: #073763;
}

.background-green {
  background-color: #73ab4e;
}

.background-red {
  background-color: #ff0000;
}

.bg-grey {
  background-color: #eaeaea;
}

.background-blue {
  background-color: #0070c0;
}

.text-blue {
  color: #0070c0;
}

.text-green {
  color: #73ab4e;
}

.text-red {
  color: #ff0000;
}

/* Message board css */
.employee-message {
  background-color: #73ab4e38;
}

.dimona-message {
  background-color: #ff000038;
}

.indii-message {
  background-color: #0070c038;
}

.required-bg {
  background-color: #ff000015;
}

.table-head-bg {
  background-color: #61bfb5;
  border: #61bfb5;
  color: white;
  font-weight: 700 !important;
}

/* Icons styles */
.banner-style {
  width: 100%;
  height: 100%;
}

.logo {
  width: 102px;
  height: 29px;
}

.login-logo {
  width: 135px;
  height: 40px;
}

.login_height {
  height: calc(100vh - 80px);
}

.header-icon {
  width: 23px;
  height: 23px;
}

.profile-icon {
  width: 45px;
  height: 45px;
}
.invite-icon svg {
  width: 4rem;
  height: 4rem;
}

.cancel-icon {
  width: 16px;
  height: 16px;
  margin-left: 80px !important;
}

.shortcut-icon {
  width: 35px;
  height: 35px;
}

.planning-icon {
  width: 30px;
  height: 30px;
}

.planning-icon-forword{
  width: 40px;
  height: 40px;
}

.planning-icon svg {
  width: 30px;
  height: 38px;
}

.search-btn {
  position: relative;
  bottom: 4.5rem;
}

.dashboard_height {
  height: calc(100vh - 100px);
  overflow: auto;
}

.dashboard-icon svg {
  width: 3.5rem;
  height: 8rem;
}

.configuration_body {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.configuration-icon svg {
  width: 3rem;
  height: 3rem;
}

.box {
  width: 20px;
  height: 20px;
}

.full-page-height {
  height: 100vh;
}

.add_employee_block {
  height: calc(100vh - 280px);
  overflow-y: auto;
}

.employee-icon {
  width: 50px;
  height: 50px;
}

.employee-icon-temp {
  width: 80px;
  height: 80px;
}

.photo-update-container {
  position: relative;
  width: 18vh;
  height: 18vh;
}

.photo-icon {
  width: 18vh;
  height: 18vh;
}

.plan-icon {
  width: 12px;
  /* height: 15px; */
}

.photo-update-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -20%);
  opacity: 0;
  transition: all 0.5s;
  width: 3em;
  height: 3em;
}

.photo-update-container:hover .photo-icon {
  filter: blur(2px) brightness(80%);
}

.photo-update-container:hover .photo-update-icon {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.hide {
  display: none;
}

.employee-detail-height {
  overflow: auto;
  height: 56.5vh;
}

.width-22 {
  width: 22% !important;
}

/* Common styles */
li,
.pointer {
  cursor: pointer;
}

.no-padd {
  padding: 0px !important;
}

.border-bottom-only {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-width: 0px 0px 1px 0px !important;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-inherit {
  font-size: inherit;
}

.font-details {
  font-size: 13px;
}

.pointer {
  cursor: pointer !important;
}

/* Buttons css */
.button-style {
  color: #fff;
  font-weight: 600;
  background-color: #61bfb5;
  border: #61bfb5;
  text-transform: capitalize;
}

/* for add-company form */
.add_company_form {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.button-confirm {
  color: #fff;
  background-color: #073763;
}

/* Dynamic content part */
.right-container {
  width: 93%;
  display: inline-flex;
  justify-content: center;
  background-color: #f3f3f3;
  height: 89.5vh;
}

.grey-background {
  background-color: #f3f3f3;
}

/* Dynamic content part */
.right-creation-container {
  width: 93%;
  /* display: flex; */
  justify-content: center;
  background-color: #f3f3f3;
}

/* Dashboard css */
.display-linebreak {
  white-space: pre-line;
}

/* Navbar css */
.navbar-brand {
  margin-right: 1.8rem !important;
}

/* Sidebar css */
.side-bar {
  min-width: 7% !important;
  min-height: 89.5vh;
  /* overflow: hidden; */
  transition: all 0.5s !important;
}

.side-bar ul {
  transition: all 0.5s !important;
}

.side-bar-open {
  /* position: absolute; */
  /* position: relative; */
  background-color: white;
  z-index: 2;
  width: 7%;
  transition: right 100s ease-in-out;
  transition: all 0.3s;
}

.side-bar-open .side-bar {
  padding-right: 4rem;
  width: max-content;
  background: white;
  overflow: visible;
  margin-bottom: 0px;
  height: 100%;
}

.sidebar-moving {
  transition: right 100s ease-in-out;
  animation: mymove 1s alternate;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
}

@keyframes mymove {
  from {
    margin-left: -100px;
  }

  to {
    margin-left: 2px;
  }
}

/* Language dropdown css */
#language-font {
  font-size: 1.2rem;
  margin-bottom: 6px !important;
}

.language-dropdown div {
  border: 0;
  margin-bottom: 2px;
  font-size: 1.25rem;
}

.language-dropdown div div {
  padding-right: 0 !important;
}

.css-1nmdiq5-menu {
  z-index: 3 !important;
}

/* Company dropdown*/
.company-dropdown div {
  /* border: 0;
    font-size: 1rem;
    width: 10rem; */
}

.arrowButtons {
  min-width: 44px;
  background: none;
  border: none;
  font-size: 22px;
}

.monthText {
  font-size: 20px;
  font-weight: 400;
}

/* Content menu css */
.account-menu {
  position: absolute;
  right: 12px;
  top: 75%;
  z-index: 2;
}

.shortcut-menu {
  position: absolute;
  right: 28%;
  top: 75%;
  z-index: 2;
}

.shortcut-menu-position {
  position: absolute;
  right: 34%;
  top: 44%;
  width: max-content;
}

.shortcut-menu-trigger {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.notification-menu {
  position: absolute;
  right: 21%;
  top: 75%;
  z-index: 2;
}

.popup-loading-icon-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

/* Company overview css */
.manage_company_tabs {
  width: calc(100% / 7);
  border: 3px solid #f3f3f3;
  border-width: 1px 1px 0px 1px !important;
  color: black;
  border-radius: 0px;
  bottom: 1px;
  padding: 12px 12px;
  text-align: center;
  display: inline-block;
}

/* Monthly overview tabs */
.monthly-overview div ul {
  text-align: center;
  padding: 8px 0px;
}

.monthly-overview div ul li {
  padding: 6px;
  border-radius: 5px;
  background: #61bfb5 !important;
  margin: 0px 10px;
  color: white;
  font-weight: 600;
}

.monthly-overview div ul li.react-tabs__tab--selected {
  background: #073763 !important;
}

.monthly-overview div div {
  border-width: thin;
}

.monthly-overview div div.react-tabs__tab-panel--selected {
  border-top: none !important;
}

/* React Tabs css*/
.react-tabs__tab-list {
  margin: 0px !important;
  border-bottom: none !important;
}

.company-tab-width {
  width: 98% !important;
  border-radius: 5px;
}

.react-tabs__tab--selected {
  background: #61bfb5 !important;
}

.react-tabs__tab {
  border: 3px solid #f3f3f3;
  border-width: 1px 1px 0px 1px !important;
  color: black;
  border-radius: 0px;
  bottom: 1px;
  padding: 12px 12px;
  text-align: center;
  width: max-content !important;
  background-color: white;
  min-width: 11%;
}

.react-tabs__tab-panel--selected {
  border: 5px solid #f3f3f3;
  border-width: 3px 0px 0px 0px;
}

.company_creation div ul li,
.width-20 {
  width: 20% !important;
}

.employee_creation div ul li {
  width: 25% !important;
}

.manage_employee_tabs {
  width: 22%;
}

.right-end-tab {
  padding: 0px;
  width: 24% !important;
  padding-left: 4%;
}

.employee-detail div li {
  /* background-color: #fff;
    border-bottom: 1px solid #fff;
    border-width: 0px 0px 3px 0px;
    margin-top: 1px; */
  width: max-content !important;
}

.signature-canvas {
  width: 100%;
  height: 100%;
  border: 1px solid #dee2e6;
}

/* .selected_emp_tab{
    border-bottom: 3px solid #61bfb5 !important;
    border-width: 0px 0px 3px 0px;
    margin-top: 1px;
} */

/* Table css */
.MuiTableHead-root,
.MuiToolbar-root {
  z-index: 0 !important;
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.42) !important;
  pointer-events: none;
}

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 0px solid black !important;
  pointer-events: none;
}

/* .MuiToolbar-gutters {
    padding: 0px !important;
    background-color: #61BFB5;
} */

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #918e8e !important;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
  border: 1px solid #918e8e;
}

/* Cards css */
.card:hover {
  box-shadow: 0px 0px 0px grey;
  transform: scale(1.02);
  cursor: pointer;
}

.card-body {
  padding: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-height {
  height: fit-content;
}

.card-height div {
  padding: 1.25rem;
}

/* Forms Style */
.form input,
textarea {
  border: 1px solid #61bfb5 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #61bfb5;
  background-color: #61bfb5;
}

.custom-control-input {
  z-index: 999 !important;
  width: 2rem;
}

.custom-control {
  z-index: 0 !important;
}

.list-group-item:hover {
  background-color:#d1f3e8;
}

.form-container {
  width: 98%;
  border-radius: 5px;
}

.form-subHeading {
  text-decoration: underline;
  color: #073763;
  font-weight: bold;
  font-size: 1.25rem;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
  top: 20px;
}

.scan-text {
  position: absolute;
  top: 20%;
}

/* Custom scroll css */
.customscroll,
.tablescroll div div div div {
  top: 0;
  right: 0;
}

.Component-horizontalScrollContainer-68 div{
  height: 100%;

}

.customscroll::-webkit-scrollbar,
.tablescroll div div div div::-webkit-scrollbar {
  width: 10px;
}

.customscroll::-webkit-scrollbar-track,
.tablescroll div div div div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.customscroll::-webkit-scrollbar-thumb,
.tablescroll div div div div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.customscroll::-webkit-scrollbar-thumb:hover,
.tablescroll div div div div::-webkit-scrollbar-thumb:hover {
  background: #073763;
  padding-top: 20px;
}

.employee-detail-height {
  overflow: auto;
  height: calc(100vh - 390px);
}

.pdf_container {
  height:auto;
  width:auto;
  position:relative;
  radius:20px;
}

.pdf_document {
  border-radius: 20px;
  /* position: relative; */
  padding: 10px;
  /* overflow: scroll; */
}

.pdf_page {
  max-width: 400px;
  width: 90vw;
  aspect-ratio: 9/13;
  display: flex;
  justify-content: center;
  margin: auto;
}

.page_controls {
  position: sticky;
  bottom :2%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 3;
  background-color: #61bfb5;
  display: inline-block;
}

.bg-gradient{
  background: linear-gradient(130deg, #61bfb5 0, #073763b7 100%) !important;
}

.page-control-icons{
  width: 2rem;
  height: 2rem;
}

.relative-position {
  position: relative;
  top:0;
  left:8%
}

.restform{

  background-size: cover;
}

.restform::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.z-index-1{
  z-index: 1;
}
.text_bold {
  font-weight: 600;
  font-size: 2.5rem;
}
.planning-remark-icon {
  /* margin-top: 30px !important; */
  position: absolute;
  top: 2px;
  right: 6px;
  width: 15px;
  height: 15px;
}
/* Media query css for bigger screens */
@media screen and (min-width: 1590px) {
  .font-12 {
    font-size: 1rem;
  }

  .card-body {
    padding: 1.25rem;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .dashboard-icon svg {
    width: 5rem;
    height: 8rem;
  }

  .configuration-icon {
    width: 3rem;
    height: 3rem;
  }

  /* .side-bar {
    min-height: 91vh !important;
  } */

  .font-inherit {
    font-size: 1rem;
  }

  .font-details {
    font-size: 16px;
  }
  .planning-remark-icon {
    /* margin-top: 30px !important; */
    position: absolute;
    top: 4px;
    right: 6px;
    width: 18px;
    height: 18px;
}
}

.border-thick {
  border-bottom: 12px solid #f3f3f3;
}

.my-account-menu {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #292929;
}

.my-account-menu:hover {
  background-color: #79d9cfba;
  color: #fff;
  font-weight: 600;
  border-radius: 0.5rem;
  transform: translate(-10, -30) scale(1.05, 1.05);
  transition: all 0.25s;
}

.active-my-account-menu {
  background-color: #61bfb5;
  color: #fff;
  font-weight: 600;
  border-radius: 0.5rem;
  padding: 1em;
}

.filepond--item-panel {
  border-radius: 10em;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #003f54;
  background-color: #003f54;
}

.custom-control-input {
  z-index: 999 !important;
  width: 2rem;
}

.custom-control {
  z-index: 0 !important;
}

.custom-switch.custom-switch-lg {
  padding-bottom: 1rem;
  padding-left: 2.25rem;
}

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 2.5rem;
  padding-top: 0.3rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  border-radius: 1rem;
  /* height: 2rem;
    width: 4rem; */
  height: 1.5rem;
  width: 3.5rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  border-radius: 1rem;
  /* height: calc(2rem - 4px);
    width: calc(2rem - 4px); */
  height: calc(2rem - 12px);
  width: calc(2.5rem - 20px);
}

.custom-switch.custom-switch-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(2rem);
}

.react-tel-input {
  height: 2.5em !important;
}

.time-picker {
  position: absolute;
  top: 100%;
  z-index: 9999;
  background-color: white;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 3px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 3px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 3px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 3px;
}

.ui-timepicker-table {
  display: inline-table;
  width: 0;
}

.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.ui-timepicker-table td {
  padding: 0.1em;
  width: 2.2em;
}

.ui-timepicker-hours,
.ui-timepicker-minutes {
  padding: 0.2em;
}

.ui-timepicker-table .ui-timepicker-title {
  line-height: 1.8em;
  text-align: center;
}

.ui-widget-header {
  border: 1px solid #61bfb5;
  background: #61bfb5;
  color: black;
  font-weight: bold;
}

.ui-helper-clearfix {
  min-height: 0;
}

.ui-timepicker-table table {
  margin: 0.15em 0 0 0;
  border-collapse: collapse;
}

.ui-timepicker-table th.periods {
  padding: 0.1em;
  width: 2.2em;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid #61bfb5;
  background: #61bfb5;
  font-weight: normal;
  color: #fff;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 1px solid #61bfb5;
  background: #e8f8f3;
  font-weight: normal;
  color: #454545;
}

.ui-widget-content {
  border: 1px solid #ddd !important;
  /* background: #E8F8F3;  */
  font-weight: normal;
  color: #454545;
}

.ui-timepicker {
  border: none;
}

.ui-timepicker-table td a {
  display: block;
  padding: 0.2em 0.3em 0.2em 0.5em;
  width: 2.2em;
  cursor: pointer;
  text-decoration: none;
}

/* Planning css */

.planning-header-row {
  width: 100%;
  font-weight: 600;
}

.height-10 {
  height: 10vh;
}

.planning-position {
  position: relative;
}

.cost-position {
  position: absolute;
  bottom: 6px;
}

.leave-position {
  position: absolute;
  bottom: 6px;
  right: 45%;
}

.add_contracts_body {
  height: calc(100vh - 175px);
  overflow-y: auto;
}

.contract-hours-pos {
  position: absolute;
  right: 8px;
  bottom: 6px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 1px;
  background-color: #fff;
  z-index: 1;
}

.ck-editor__editable_inline {
  min-height: 400px;
}

/* New date picker css */
.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #e8f8f3;
  color: #003f54 !important;
}
.custom-calendar + div,
.custom-calendar {
  z-index: 9999999 !important;
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
  border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
  border-bottom: 1px solid bisque;
}

.rmdp-week-day {
  color: #003f54 !important;
  font-weight: 700 !important;
}

.rmdp-day,
.rmdp-arrow,
.rmdp-header-values {
  color: #003f54 !important;
}

.rmdp-arrow,
.rmdp-header-values {
  font-weight: 700 !important;
}

.rmdp-arrow {
  border-color: #003f54 !important;
}

.rmdp-input {
  /* border: 1px solid #61bfb5 !important;
    background-color: #E8F8F3 !important; */
  border-radius: 5px;
  height: 38px !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
}

.rmdp-container {
  width: 100%;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #61bfb5 !important;
  box-shadow: 0 0 3px #61bfb5;
  color: #fff;
}

.rmdp-day.rmdp-today span {
  background-color: unset !important;
  color: #003f54 !important;
}

.rmdp-today span:not(.highlight) {
  background-color: #003f54 !important;
  box-shadow: 0 0 3px #61bfb5;
  color: #fff !important;
}

.underline {
  text-decoration: underline;
}

.planning-timeline div div div div {
  overflow: hidden !important;
}

.planning-timeline div div div div svg g text {
  display: none !important;
}

.border-x-none {
  border-right: none !important;
  border-left: none !important;
}

.width-90 {
  width: 90% !important;
}

/* Container for the file input */
.file-input-container {
  position: relative;
  display: inline-block;
  border: 2px dashed lightgray;
  padding: 4px 10px;
  cursor: pointer;
  height: 5rem;
}

/* Hidden input button */
.file-input {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  z-index: 1;
}

/* Label styling */
.file-label {
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
}

/* Additional styling when a file is selected */
.file-input:checked + .file-label {
  background-color: greenyellow;
  border: 1px solid gray;
}

#file-name-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gray;
}

.file-name-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gray;
}

.popup-tabs div ul li {
  width: 50% !important;
}

.function-card {
  width: 22%;
  padding-left: 10px;
}

.w-max-content {
  width: max-content;
}

.planning_body {
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.planing_tabs {
  border: 3px solid #f3f3f3;
  border-width: 1px 1px 0px 1px !important;
  color: black;
  border-radius: 0px;
  bottom: 1px;
  padding: 12px 12px;
  text-align: center;
  width: 11%;
  background-color: white;
  list-style: none;
}

.planing_tabs:focus-visible,
.manage_company_tabs:focus-visible {
  outline: none !important;
}

.panning_overview_table {
  overflow: auto;
  height: calc(100vh - 320px);
  /* height: calc(100vh - 400px); */
}

.panning_overview_table_heading {
  font-weight: bold;
}
.panning_overview_times {
  font-weight: bold;
  padding: 0px 28px !important;
}

.uurrooster_table {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.uurrooster_table table thead th,
.uurrooster_table table tbody td {
  text-align: center;
}

.uurrooster_table table thead {
  position: sticky;
  top: 0;
  background: inherit;
  background-color: #61bfb5 !important;
  color: white;
  outline: 1px solid gray !important;
}

.disabled-icon {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.margin-minus {
  margin-top: -25px;
}

.add_sectors_body {
  height: calc(100vh - 333px);
  overflow-y: auto;
}

.form_body {
  height: calc(100vh - 315px);
  overflow: auto;
}

.oth_form_planning {
  height: 58vh;
  overflow-y: auto;
}

@media (max-width: 1400px) {
  body,
  html {
    font-size: 14px !important;
  }

  .oth_form_planning {
    height: 54vh;
    overflow-y: auto;
  }

  .dashboard-icon {
    width: 3.5rem;
    height: 6rem;
  }

  .planing_tabs {
    width: 15% !important;
  }

  .navbar-brand {
    margin-right: 1.4rem !important;
  }

  .uurrooster_table {
    height: calc(100vh - 265px);
    overflow-y: auto;
  }

  .manage_employee_tabs {
    width: 25%;
  }

  .manage_employee_left_block .MuiToolbar-gutters {
    padding: 0 !important;
  }

  .add_employee_block {
    height: calc(100vh - 255px);
    overflow-y: auto;
  }

  .add_company_form {
    height: calc(100vh - 280px);
  }

  .employee-detail-height {
    overflow: auto;
    height: calc(100vh - 360px);
  }

  .add_contracts_body {
    height: calc(100vh - 158px);
  }

  .add_sectors_body {
    height: calc(100vh - 304px);
  }

  .form_body {
    height: calc(100vh - 286px);
    overflow: auto;
  }

  .notification-menu {
    right: 25%;
  }

  .shortcut-menu {
    right: 35%;
  }
}

.eye_icon {
  position: absolute;
  right: 0%;
  top:42%;
  transform: translateY(-50%);
  z-index: 3;
  background-color: white;
}

@media (max-width: 1200px) {
  /* .add_employee_block {
    height: calc(100vh - 266px);
    overflow-y: auto;
  } */
  .notification-menu {
    right: 33%;
  }

  .shortcut-menu {
    right: 46%;
  }

  .oth_form_planning {
    height: 52vh;
    overflow-y: auto;
  }
}

@media (max-width: 1718px) {
  .manage_company_tabs {
    width: calc(100% / 7);
    border: 3px solid #f3f3f3;
    border-width: 1px 1px 0px 1px !important;
    color: black;
    border-radius: 0px;
    bottom: 1px;
    padding: 12px 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .manage_company_header {
    flex-direction: column-reverse;
  }

  .add_company_block {
    text-align: right;
    width: 100%;
    margin: 1rem 0px 0.5rem 0px;
  }
}

/* custom scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #61bfb5 !important;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* custom scrollbar */

.add_btn {
  background-color: #073763;
  color: white;
  text-decoration: none !important;
  padding: 10px 20px;
  border: 2px solid #073763;
  border-radius: 0.25rem !important;
}

.add_btn svg {
  margin-right: 5px;
}

.add_btn:hover {
  background-color: white;
  color: #073763;
  text-decoration: none !important;
  padding: 10px 20px;
  border: 2px solid #073763;
}

a:hover {
  text-decoration: none;
}

.add_btn svg path {
  fill: white;
}

.add_btn:hover svg path {
  fill: #073763;
}

.open_shift_Main {
  height: 93%;
}

.plannign_overview_weekly_employee_title {
  max-width: 9vw;
  /* width: 100%; */
  display: inline-block;
}

.day_overview_name {
  max-width: 8vw;
}

.Overview_table_workstation tbody td {
  vertical-align: middle;
}

.top_100px {
  top: 100px;
}

.sticky tr th {
  outline: 1px solid #918e8e;
}

tr th:last-child {
  width: auto !important;
  min-width: 150px !important;
}

.add_taxes form,
.add_public_holidays form {
  display: block;
}

.add_leave_popup > div > div:nth-child(1) {
  overflow: unset !important;
}

.table-fixed {
  table-layout: fixed;
}

.w-12 {
  width: 12%;
}

.form-control {
  min-height: 38px;
}

.login_lang_dropdown {
  top: 0;
  right: 0;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.w-10 {
  width: 10%;
}

.width-10 {
  width: 10rem;
}

.w-90 {
  width: 90%;
}

.reporting table {
  width: 100% !important;
}

.reporting th {
  width: 7.14% !important;
  background-color: #61bfb5;
  color: white;
  padding: 8px 0px;
  font-weight: bold;
}

.reporting td {
  width: 7.14% !important;
}

.reporting tr th:last-child {
  width: 7.14% !important;
}

.font-weight-600 {
  font-weight: 600;
}

.bg-qrcode {
  background-color: #61bfb517;
}

.slide_down_container{
  content: '';
  position: absolute;
  top: 0;
  left: 0; 
  background-color: rgb(255, 255, 255); /* Adjust as needed */
  z-index: 2;
}


.event-calendar .react-calendar__navigation {
  margin-bottom: 2px !important;
  position: absolute;
  top: 0px;
  left: 20%;
}

.event-calendar .react-calendar__viewContainer {
  margin-top: 10%;
}

.event-calendar .react-calendar__tile {
  padding: 10px 6.6667px;
}

.event-calendar .react-calendar {
  border: 1px solid #a0a09633;
}

.event-button {
  margin-inline: 0px !important;
}

/* .event-calendar .react-calendar__navigation__prev-button {
  margin-right: 92px;
}

.event-calendar .react-calendar__navigation__next-button {
  margin-left: 100px;
} */
.date-head {
  vertical-align: top !important;
}

.belgium-flag {
  width: 22px;
  height: 16px;
}

.read-only {
  background-color: white;
  border: none;
  text-align: center;
  color: var(--readonly-text-color);
}

.bg-yellow {
  background-color: #FCEEA7;
}
.bg-blue {
  background-color: #BDD7EE !important;
}
.bg-red {
  background-color: #ff000096 !important;
}
.Spreadsheet__table td {
  text-align: center !important;
}

.w-20 {
  max-width: 20% !important;
}

.w-40 {
  width: 40% !important;
}

.H-27 {
  height: 27vh;
}

.worked-hour-col {
  background-color: #69cd7b8a;
  color: black !important;
}




body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 520px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-right: 0px;
  margin-top: 5px;
  transition: width 0.3s ease-in-out;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 520px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.Spreadsheet__table > tbody > tr:first-child {
  top: 8%;
  position: -webkit-sticky !important;
  position: sticky !important;
  right: 0;
  left: 0;
  z-index: 999;
}

.sroll {
  overflow: scroll;
}

.top_0px {
  top:0
}

.right_0px {
  right:0
}

.message-board-icon {
  width: 15px;
  height: 15px;
}

.with-notification {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 1;
}
