.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 275px;
  margin-left: 36%;
  margin-top: 4%;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
